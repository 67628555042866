import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link as RouterLink } from 'react-router-dom';
import bluelogo from '../Images/bluelogo.png';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

const logoStyle = {
    width: '140px',
    height: 'auto',
};

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" mt={1}>
            {'Copyright © '}
            <Link href="https://helixtech.org/">HelixTechnologies LLC&nbsp;</Link>
            {new Date().getFullYear()}
        </Typography>
    );
}

export default function Footer() {
    const handleScrollToTop = () => {
        window.scrollTo(0, 0);
    };


    const [open, setOpen] = React.useState(false);
    const [openTerms, setOpenTerms] = React.useState(false);
    const [openPrivacy, setOpenPrivacy] = React.useState(false);

    const handleOpenTerms = () => {
        setOpenTerms(true);
    };

    const handleCloseTerms = () => {
        setOpenTerms(false);
    };

    const handleOpenPrivacy = () => {
        setOpenPrivacy(true);
    };

    const handleClosePrivacy = () => {
        setOpenPrivacy(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: { xs: 4, sm: 8 },
                py: { xs: 8, sm: 10 },
                textAlign: { sm: 'center', md: 'left' },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    width: '100%',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 4,
                        minWidth: { xs: '100%', sm: '60%' },
                    }}
                >
                    <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
                        <Box sx={{ ml: '-15px' }}>
                            <Link to="/">
                                <img
                                    src={bluelogo}
                                    style={logoStyle}
                                    alt="HelixTech Logo"
                                />
                            </Link>
                        </Box>
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: { xs: 'none', sm: 'flex' },
                        flexDirection: 'column',
                        gap: 1,
                    }}
                >
                    <Typography variant="body2" fontWeight={600}>
                        Company
                    </Typography>
                    <Link component={RouterLink} to="/Meet-The-Team" color="text.secondary" onClick={handleScrollToTop}>
                        Meet the Team
                    </Link>
                    <Link component={RouterLink} to="/AboutUs" color="text.secondary" onClick={handleScrollToTop}>
                        About Us
                    </Link>

                </Box>
                <Box
                    sx={{
                        display: { xs: 'none', sm: 'flex' },
                        flexDirection: 'column',
                        gap: 1,
                    }}
                >
                    <Typography variant="body2" fontWeight={600}>
                        Legal
                    </Typography>
                    <Link color="text.secondary" href="#" onClick={handleOpenTerms}>
                        Terms
                    </Link>
                    <Link color="text.secondary" href="#" onClick={handleOpenPrivacy}>
                        Privacy
                    </Link>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    pt: {xs: 4, sm: 8},
                    width: '100%',
                    borderTop: '1px solid',
                    borderColor: 'divider',
                }}
            >
                <div>
                    <Link color="text.secondary" href="#" onClick={handleOpenPrivacy}>
                        Privacy Policy
                    </Link>

                    <Dialog open={openPrivacy} onClose={handleClosePrivacy}
                            sx={{'& .MuiDialog-paper': {borderRadius: '12px', padding: '20px'}}}>
                        <DialogTitle sx={{textAlign: 'center', paddingBottom: '8px'}}>Privacy Policy</DialogTitle>
                        <DialogContent sx={{color: 'text.secondary', fontSize: '14px', lineHeight: '20px'}}>
                            <Typography>
                                Privacy Policy

                                HelixTech operates the website https://helixtech.org. This page informs you of our policies regarding the collection, use, and disclosure of Personal Information we receive from users of the site.

                                We do not sell your Personal Information to third parties. We only use your Personal Information for providing and improving the site. By using the site, you agree to the collection and use of information in accordance with this policy.

                                Information Collection and Use

                                While using our site, we may collect certain non-personally identifiable information, such as the country of origin of our website visitors, to analyze site traffic and improve user experience. We do not collect or store IP addresses or any other personally identifiable information unless provided voluntarily by users through contact forms or other means.

                                Cookies

                                Our website may use cookies to enhance user experience. Cookies are files with a small amount of data which may include an anonymous unique identifier. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our site.

                                Security

                                The security of your Personal Information is important to us. We implement appropriate technical and organizational measures to protect the information we collect. However, please be aware that no method of transmission over the Internet, or method of electronic storage, is 100% secure.

                                Changes to This Privacy Policy

                                This Privacy Policy is effective as of [insert date] and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.

                                We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the site after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.

                                Contact Us

                                If you have any questions about this Privacy Policy, please contact us at ian.mendoza@helixtech.org

                            </Typography>
                        </DialogContent>
                        <DialogActions sx={{justifyContent: 'center', paddingTop: '16px'}}>
                            <Button onClick={handleClosePrivacy} sx={{color: 'primary.main'}}>Close</Button>
                        </DialogActions>
                    </Dialog>

                    <Typography display="inline" sx={{mx: 0.5, opacity: 0.5}}>
                        &nbsp;•&nbsp;
                    </Typography>
                    <Link color="text.secondary" href="#" onClick={handleOpenTerms}>
                        Terms of Service
                    </Link>
                    <Dialog open={openTerms} onClose={handleCloseTerms}
                            sx={{'& .MuiDialog-paper': {borderRadius: '12px', padding: '20px'}}}>
                        <DialogTitle sx={{textAlign: 'center', paddingBottom: '8px'}}>Terms of Service</DialogTitle>
                        <DialogContent sx={{color: 'text.secondary', fontSize: '14px', lineHeight: '20px'}}>
                            <Typography>
                                Welcome to HelixTech!

                                These terms and conditions outline the rules and regulations for the use of HelixTech's Website, located at https://helixtech.org.

                                By accessing this website, we assume you accept these terms and conditions. Do not continue to use HelixTech if you do not agree to take all of the terms and conditions stated on this page.

                                The following terminology applies to these Terms and Conditions, Privacy Statement, and Disclaimer Notice and all agreements: "Client", "You", and "Your" refers to you, the person accessing this website and compliant to HelixTech’s terms and conditions. "The Company", "Ourselves", "We", "Our", and "Us", refers to HelixTech. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance, and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of provision of HelixTech’s stated services, in accordance with and subject to, prevailing law of Netherlands. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.

                                Cookies

                                We employ the use of cookies. By accessing HelixTech, you agreed to use cookies in agreement with HelixTech's Privacy Policy.

                                Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.

                                License

                                Unless otherwise stated, HelixTech and/or its licensors own the intellectual property rights for all material on HelixTech. All intellectual property rights are reserved. You may access this from HelixTech for your own personal use subjected to restrictions set in these terms and conditions.

                                You must not:

                                Republish material from HelixTech
                                Sell, rent, or sub-license material from HelixTech
                                Reproduce, duplicate, or copy material from HelixTech
                                Redistribute content from HelixTech
                                This Agreement shall begin on the date hereof.

                                Hyperlinking to our Content

                                The following organizations may link to our Website without prior written approval:

                                Government agencies;
                                Search engines;
                                News organizations;
                                Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and
                                System-wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Website.
                                These organizations may link to our home page, to publications, or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party’s site.

                                We may consider and approve other link requests from the following types of organizations:

                                commonly-known consumer and/or business information sources;
                                dot.com community sites;
                                associations or other groups representing charities;
                                online directory distributors;
                                internet portals;
                                accounting, law, and consulting firms; and
                                educational institutions and trade associations.
                                We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of HelixTech; and (d) the link is in the context of general resource information.

                                These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site.

                                If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to emiliano.rodriguez@helixtech.org. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.

                                Approved organizations may hyperlink to our Website as follows:

                                By use of our corporate name; or
                                By use of the uniform resource locator being linked to; or
                                By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party’s site.
                                No use of HelixTech's logo or other artwork will be allowed for linking absent a trademark license agreement.

                                iFrames

                                Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.

                                Content Liability

                                We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that arise on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene, or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of any third-party rights.

                                Reservation of Rights

                                We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amend these terms and conditions and it’s linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.

                                Removal of links from our website

                                If you find any link on our Website that is offensive for any reason, you are free to contact and inform us at any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.

                                We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.

                                Disclaimer

                                To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website. Nothing in this disclaimer will:

                                limit or exclude our or your liability for death or personal injury;
                                limit or exclude our or your liability for fraud or fraudulent misrepresentation;
                                limit any of our or your liabilities in any way that is not permitted under applicable law; or
                                exclude any of our or your liabilities that may not be excluded under applicable law.
                                The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort




                            </Typography>
                        </DialogContent>
                        <DialogActions sx={{justifyContent: 'center', paddingTop: '16px'}}>
                            <Button onClick={handleCloseTerms} sx={{color: 'primary.main'}}>Close</Button>
                        </DialogActions>
                    </Dialog>
                    <Copyright/>
                </div>
                <Stack
                    direction="row"
                    justifyContent="left"
                    spacing={1}
                    useFlexGap
                    sx={{
                        color: 'text.secondary',
                    }}
                >
                    <IconButton
                        color="inherit"
                        href="https://www.linkedin.com/company/helixtech"
                        aria-label="LinkedIn"
                        sx={{alignSelf: 'center'}}
                    >
                        <LinkedInIcon/>
                    </IconButton>
                </Stack>
            </Box>

        </Container>
    );
}