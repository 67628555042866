import React from 'react';
import { alpha, Card, CardContent, Typography, Box, Avatar, Container, CardHeader, CardActions, Button } from '@mui/material';
import Footer from "./Footer";

const CardComponent = ({ name, position, description, image }) => (
    <Card sx={{ maxWidth: 345, boxShadow: 3 }}>
        <CardHeader
            avatar={
                <Avatar alt={name} src={image} sx={{ width: 56, height: 56 }} />
            }
            title={name}
            subheader={position}
        />
        <CardContent>
            <Typography variant="body2" color="text.secondary">
                {description}
            </Typography>
        </CardContent>
        <CardActions>
            <Button size="small" color="primary">
                Learn More
            </Button>
        </CardActions>
    </Card>
);

const MeetTheTeam = () => {
    return (
        <>
            <Box
                id="meet-the-team"
                sx={(theme) => ({
                    width: '100%',
                    backgroundImage:
                        theme.palette.mode === 'light'
                            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
                            : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
                    backgroundSize: '100% 20%',
                    backgroundRepeat: 'no-repeat',
                })}
            >
                <Container
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        pt: { xs: 14, sm: 20 },
                        pb: { xs: 8, sm: 12 },
                    }}
                >
                    <Typography
                        variant="h1"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignSelf: 'center',
                            textAlign: 'center',
                            fontSize: 'clamp(3.5rem, 10vw, 4rem)',
                        }}
                    >
                        This is Us.
                    </Typography>
                    <Typography
                        variant="h5"
                        align="center"
                        color="text.secondary"
                        paragraph
                        sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%' } }}
                    >
                        2 Software Engineers, One dream: To make yours come true - One line at a time.
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-around', gap: 2 }}>
                            <CardComponent
                                name="Emiliano Rodriguez"
                                position="Co-Founder and Software Engineer"
                                description="Hey! I'm Emi, and I'm a software engineer. I love to code and make things work in a special type of way.
                                Im currently a student at the University of Texas at El Paso, and I'm majoring in Computer Science. I might
                                still be a student, however I have experience working at a handful of big companies, Like GE Appliances,
                                Copile, WestStar Bank and more to name a few. Your business is my business, and I'm here to help you succeed
                                in the digital world."
                                image="image1.jpg"
                            />
                            <CardComponent
                                name="Ian Mendoza"
                                position="Co-Founder and Software Engineer"
                                description="Hello! I'm Ian, and I'm a software engineer. I live to see you succeed by any
                                means necessary. I'm currently a student at the University of Texas at El Paso, and I'm majoring in
                                computer science. I have experience working at a handful of big companies as a consultant with
                                work in Tropicana Homes, Warrior Alliegance, and a whole lot more. Your dream is my goal, and I'm here
                                to help you achieve it."
                                image="image2.jpg"
                            />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <CardComponent
                                name="Lembit Venegas"
                                position="Executive Marketing Intern"
                                description="Hey! I'm Lembit, and I'm a marketing intern. I love to see businesses grow and succeed in the digital world.
                                I'm currently a student at the University of Texas at El Paso, and I'm majoring in Marketing. I work day and night
                                to make sure your business gets the attention it deserves. Your success is my success, and I'm here to help you."
                                image="image3.jpg"
                            />
                        </Box>
                    </Box>
                </Container>
            </Box>
            <Footer />
        </>
    );
}

export default MeetTheTeam;