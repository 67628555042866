import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';

const items = [

    {
        icon: <ConstructionRoundedIcon />,
        title: 'Application Development',
        description:
            'Let us create your program custom tailored to your business. You dream it - we build it.',
    },
    {
        icon: <DocumentScannerIcon />,
        title: 'Data Analytics and Visualization',
        description:
            'Visualize your data and understand it. Let us handle the heavy lifting.'
    },
    {
        icon: <ThumbUpAltRoundedIcon />,
        title: 'Webpage Development and Upkeep',
        description:
            'Create your dream website for your brand, or update your current site for the most up-to-date features and functionality.'
    },
    {
        icon: <AutoFixHighRoundedIcon />,
        title: 'AI Functionality',
        description:
            'Integrate your applications and websites with the power of AI for a wide variety of tasks.',
    },
    {
        icon: <AddReactionIcon />,
        title: 'Feature Implementation',
        description:
            'We will create and add new exciting features to your webpages such as chatbots or schedulers.',
    },
    {
        icon: <AddShoppingCartIcon />,
        title: 'E-Commerce Development',
        description:
            'We will develop and launch any customized online shop for your business.',
    },
];

export default function ProductsWeOffer() {
    return (
        <Box
            id="Products"
            sx={{
                pt: { xs: 4, sm: 12 },
                pb: { xs: 8, sm: 16 },
                color: 'white',
                bgcolor: '#06090a',
            }}
        >
            <Container
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: { xs: 3, sm: 6 },
                }}
            >
                <Box
                    sx={{
                        width: { sm: '100%', md: '60%' },
                        textAlign: { sm: 'left', md: 'center' },
                    }}
                >
                    <Typography component="h2" variant="h4">
                        What we can do for you.
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'grey.400' }}>
                        Explore some of the many services we offer to help you. Don't feel limited to just these options
                        - we can do so much more.
                    </Typography>
                </Box>
                <Grid container spacing={2.5}>
                    {items.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Stack
                                direction="column"
                                color="inherit"
                                component={Card}
                                spacing={1}
                                useFlexGap
                                sx={{
                                    p: 3,
                                    height: '100%',
                                    border: '1px solid',
                                    borderColor: 'grey.800',
                                    background: 'transparent',
                                    backgroundColor: 'grey.900',
                                    transition: '0.3s',
                                    '&:hover': {
                                        backgroundColor: 'grey.800',
                                    },

                                }}
                            >
                                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                                <div>
                                    <Typography fontWeight="medium" gutterBottom>
                                        {item.title}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: 'grey.400' }}>
                                        {item.description}
                                    </Typography>
                                </div>
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
}