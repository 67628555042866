import * as React from 'react';
import { alpha, Box, Container, Typography } from '@mui/material';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import Footer from './Footer';

const AboutUs: React.FC = () => {
    return (
        <>
            <Box
                id="about-us"
                sx={(theme) => ({
                    width: '100%',
                    backgroundImage:
                        theme.palette.mode === 'light'
                            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
                            : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
                    backgroundSize: '100% 20%',
                    backgroundRepeat: 'no-repeat',
                })}
            >
                <Container
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        pt: { xs: 14, sm: 20 },
                        pb: { xs: 8, sm: 12 },
                    }}
                >
                    <Typography
                        variant="h1"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignSelf: 'center',
                            textAlign: 'center',
                            fontSize: 'clamp(3.5rem, 10vw, 4rem)',
                        }}
                    >
                        About Us.
                    </Typography>
                    <Typography
                        variant="h5"
                        align="center"
                        color="text.secondary"
                        paragraph
                        sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%' } }}
                    >
                        Here's how it all started.
                    </Typography>
                    <Timeline position="alternate">
                        <TimelineItem>
                            <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Typography variant="h6" component="span">
                                    February 2024 - This is us.
                                </Typography>
                                <Typography>Our founders, from El Paso, Texas are Software Engineers. One Winter day, A idea
                                    sparked in Emi's head about starting a firm, with a goal of giving students the opportunity
                                    to intern and kick off their professional careers, and allowing small businesses get their hands
                                    on the newest technology and software at a absolute fraction of a price.
                                    Ian, Surprisingly had the exact same idea the day before. After that day, the Idea was in the air.</Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Typography variant="h6" component="span">
                                    March 2024 - The Idea.
                                </Typography>
                                <Typography>We played around with the idea of owning something tech related. Both of us
                                    had a thing for helping businesses grow and giving fair chance for all. We looked into
                                    creating a business, centered around - well... businesses! </Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Typography variant="h6" component="span">
                                    April 2024 - All Hands on Deck.
                                </Typography>
                                <Typography>We spent every our of our free time working on this project. From the website,
                                    to Logo, to all graphic effects and marketing. We wanted this to be our pride and joy, and we treated
                                    it as such. Finally, everything was complete. Now all we need, is you.</Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineSeparator>
                                <TimelineDot />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Typography variant="h6" component="span">
                                    The Future - What's Next?
                                </Typography>
                                <Typography>Well, its to early to say. But we have a bright future ahead of us. We love our
                                    clients, and they love our work! We can only hope we can start expanding our operations very soon. Thanks
                                    to you.</Typography>
                            </TimelineContent>
                        </TimelineItem>
                    </Timeline>
                </Container>
            </Box>
            <Footer />
        </>
    );
}

export default AboutUs;