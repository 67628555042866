import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
      <Container
          id="faq"
          sx={{
            pt: { xs: 4, sm: 12 },
            pb: { xs: 8, sm: 16 },
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: { xs: 3, sm: 6 },
          }}
      >
        <Typography
            component="h2"
            variant="h4"
            color="text.primary"
            sx={{
              width: { sm: '100%', md: '60%' },
              textAlign: { sm: 'left', md: 'center' },
            }}
        >
          Frequently asked questions
        </Typography>
        <Box sx={{ width: '100%' }}>
          <Accordion
              expanded={expanded === 'panel1'}
              onChange={handleChange('panel1')}
          >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1d-content"
                id="panel1d-header"
            >
              <Typography component="h3" variant="subtitle2">
                What if im a charity/non-profit?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                  variant="body2"
                  gutterBottom
                  sx={{ maxWidth: { sm: '100%', md: '70%' } }}
              >
                We offer highly specialized prices to charities and non-profits, contact us today and schedule a free consultation to review any software needs your organization has.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
              expanded={expanded === 'panel2'}
              onChange={handleChange('panel2')}
          >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2d-content"
                id="panel2d-header"
            >
              <Typography component="h3" variant="subtitle2">
                How does pricing work? Are there any hidden fees?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                  variant="body2"
                  gutterBottom
                  sx={{ maxWidth: { sm: '100%', md: '70%' } }}
              >
                If we had pricing set in stone, we would be doing a disservice to you and ultimately to our values. We
                believe in providing a service that is tailored to your needs and budget. On a tight budget? Thats okay -
                we'll work with you. Everything is negotiable, from your project - to payment plans. Clarity is key, and we
                will always be transparent with you.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
              expanded={expanded === 'panel3'}
              onChange={handleChange('panel3')}
          >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3d-content"
                id="panel3d-header"
            >
              <Typography component="h3" variant="subtitle2">
                What makes us worthy of your business?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                  variant="body2"
                  gutterBottom
                  sx={{ maxWidth: { sm: '100%', md: '70%' } }}
              >
                As a client, we dont see you as a one time buyer. We strive to keep you coming back to us because at the
                end of the day - we are not just selling a service, We are selling a relationship built on trust and
                dependability. Apart from our stellar team members and overachieving solutions. We are here for you 24/7
                to ensure if you run into an issue, we are there to fix it - and thats our promise to you.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
              expanded={expanded === 'panel4'}
              onChange={handleChange('panel4')}
          >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4d-content"
                id="panel4d-header"
            >
              <Typography component="h3" variant="subtitle2">
                Will I have to pay for changes/features before product delivery?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                  variant="body2"
                  gutterBottom
                  sx={{ maxWidth: { sm: '100%', md: '70%' } }}
              >
                Due to to the nature of a project, we'll do out best to make sure you are satisfied with the final product.
                If there are major changes you require, we will work with you to ensure that you are satisfied with both
                the project and the pricing. We will discuss the changes with you and any pricing changes (If any!).
                There will never be any "surprises" at delivery of the product.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Container>
  );
}