import * as React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';

import WbSunnyRoundedIcon from '@mui/icons-material/WbSunnyRounded';
import ModeNightRoundedIcon from '@mui/icons-material/ModeNightRounded';

function ToggleColorMode({ mode, toggleColorMode }) {
    return (
        <IconButton
            onClick={toggleColorMode}
            color="primary"
            aria-label="Theme toggle button"
            size="small"
        >
            {mode === 'dark' ? (
                <WbSunnyRoundedIcon fontSize="small" />
            ) : (
                <ModeNightRoundedIcon fontSize="small" />
            )}
        </IconButton>
    );
}

ToggleColorMode.propTypes = {
    mode: PropTypes.oneOf(['dark', 'light']).isRequired,
    toggleColorMode: PropTypes.func.isRequired,
};

export default ToggleColorMode;