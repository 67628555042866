import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import Snackbar from '@mui/material/Snackbar';
import TextField from "@mui/material/TextField";
import Alert from '@mui/material/Alert';


/*

const tiers = [
  {
    title: 'Integrations and Procedure',
    price: '500 - $5,000',
    description: [
      'Small custom scripts',
      'API plugins',
      'Chatbots, AI magic',
      'Bug squashing',
    ],
    buttonText: 'Sign up for free',
    buttonVariant: 'outlined',
  },
  {
    title: 'Professional Webpage',
    subheader: 'Recommended',
    price: '4,000+',
    description: [
      'Fully customized to your vision',
      'Consistent maintenance',
      'Priority email support',
      'Simple data logic',
    ],
    buttonText: 'Start now',
    buttonVariant: 'contained',
  },
  {
    title: 'Full Stack Application',
    price: '8,000+',
    description: [
      'Desktop App',
      'Web Apps',
      'Database features',
      'Complex data logic',
    ],
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },
];

export default function FormAndPricing() {
  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          Our FormAndPricing
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Because of the nature of software development, and the infinitely wide range of possible services, our prices are revolved around a case by case basis depending on your needs. Below is a general guideline of common services we offer:
        </Typography>
      </Box>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {tiers.map((tier) => (
          <Grid
            item
            key={tier.title}
            xs={12}
            sm={tier.title === 'Enterprise' ? 12 : 6}
            md={4}
          >
            <Card
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                border: tier.title === 'Professional' ? '1px solid' : undefined,
                borderColor:
                  tier.title === 'Professional' ? 'primary.main' : undefined,
                background:
                  tier.title === 'Professional'
                    ? 'linear-gradient(#033363, #021F3B)'
                    : undefined,
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    mb: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    color: tier.title === 'Professional' ? 'grey.100' : '',
                  }}
                >
                  <Typography component="h3" variant="h6">
                    {tier.title}
                  </Typography>
                  {tier.title === 'Professional' && (
                    <Chip
                      icon={<AutoAwesomeIcon />}
                      label={tier.subheader}
                      size="small"
                      sx={{
                        background: (theme) =>
                          theme.palette.mode === 'light' ? '' : 'none',
                        backgroundColor: 'primary.contrastText',
                        '& .MuiChip-label': {
                          color: 'primary.dark',
                        },
                        '& .MuiChip-icon': {
                          color: 'primary.dark',
                        },
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'baseline',
                    color: tier.title === 'Professional' ? 'grey.50' : undefined,
                  }}
                >
                  <Typography component="h3" variant="h2">
                    ${tier.price}
                  </Typography>
                  <Typography component="h3" variant="h6">
                    &nbsp;
                  </Typography>
                </Box>
                <Divider
                  sx={{
                    my: 2,
                    opacity: 0.2,
                    borderColor: 'grey.500',
                  }}
                />
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{
                      py: 1,
                      display: 'flex',
                      gap: 1.5,
                      alignItems: 'center',
                    }}
                  >
                    <CheckCircleRoundedIcon
                      sx={{
                        width: 20,
                        color:
                          tier.title === 'Professional'
                            ? 'primary.light'
                            : 'primary.main',
                      }}
                    />
                    <Typography
                      component="text"
                      variant="subtitle2"
                      sx={{
                        color:
                          tier.title === 'Professional' ? 'grey.200' : undefined,
                      }}
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={tier.buttonVariant}
                  component="a"
                  href="/material-ui/getting-started/templates/checkout/"
                  target="_blank"
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Stack
          direction={{xs: 'column', sm: 'row'}}
          alignSelf="center"
          spacing={1}
          useFlexGap
          sx={{pt: 2, width: {xs: '100%', sm: 'auto'}}}
      >
        <TextField
            id="outlined-basic"
            hiddenLabel
            size="small"
            variant="outlined"
            aria-label="Enter your email address"
            placeholder="Your email address"
            inputProps={{
              autocomplete: 'off',
              ariaLabel: 'Enter your email address',
            }}
        />
        <Button variant="contained" color="primary">
          Contact Us
        </Button>
      </Stack>

    </Container>
  );
}
*/
export default function FormAndPricing() {
    const [isClicked, setIsClicked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleClick = () => {
        setIsClicked(true);
        setIsLoading(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <Container
            id="pricing"
        >
            <Container
                id="contact"
                sx={{
                    pt: { xs: 4, sm: 12 },
                    pb: { xs: 8, sm: 16 },
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: { xs: 3, sm: 6 },
                }}
            >
                <Typography component="h2" variant="h4" color="text.primary">
                    Get in touch.
                </Typography>
                <Typography variant="body1" color="text.secondary">
                    Please fill out the form below and we will get back to you as soon as possible. If you are requesting
                    a quote, Please list any details and the best potential way to contact you. We'd love to hear from you.
                </Typography>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': {m: 1, width: '100%'},
                        width: '100%',
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <input type="hidden" name="_template" value="table"/>
                    <input type="hidden" name="_next" value="https://helixtech.org"/>
                    <div>
                        <TextField required id="name" name="name" label="Name" fullWidth sx={{textAlign: 'center'}}/>
                        <TextField required id="phone-number" name="phone-number" label="Phone Number" fullWidth
                                   sx={{textAlign: 'center'}}/>
                    </div>
                    <div>
                        <TextField required id="email" name="email" label="Email" fullWidth sx={{textAlign: 'center'}}/>
                    </div>
                    <div>
                        <TextField
                            id="project-explanation"
                            name="project-explanation"
                            label="Project Explanation"
                            multiline
                            rows={4}
                            fullWidth
                            sx={{textAlign: 'center'}}
                        />
                    </div>
                    <Button
                        variant="contained"
                        color={isClicked ? "success" : "primary"}
                        type="submit"
                        onClick={handleClick}
                    >
                        {isClicked ? "Thank you." : "Submit"}
                    </Button>
                </Box>
                <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                    <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                        Form submitted successfully!
                    </Alert>
                </Snackbar>
            </Container>
        </Container>
    );
}