import * as React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import Footer from './components/Footer';
import getLPTheme from './getLPTheme';
import Divider from "@mui/material/Divider";
import { Helmet } from 'react-helmet';
import MeetTheTeam from './components/MeetTheTeam';
import FormAndPricing from "./components/FormAndPricing";
import Procedure from "./components/Procedure";
import ProductsWeOffer from "./components/productsWeOffer";
import AboutUs from './components/AboutUs';
import FAQ from './components/FAQ';

export default function LandingPage() {
    const [mode, setMode] = React.useState('dark');
    const [showCustomTheme] = React.useState(true);
    const LPtheme = createTheme(getLPTheme(mode));
    const defaultTheme = createTheme({ palette: { mode } });

    const toggleColorMode = () => {
        setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
    };

    return (
        <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
            <CssBaseline />
            <Helmet>
                <title>Helix Tech | Your dreams, finished one line at a time.</title>
                <meta name="description" content="HelixTech: Premier software engineering consulting firm specializing in web development, mobile apps, and custom software solutions. Get in touch today!" />
                <meta property="og:title" content="Helix Tech | Your dreams, finished one line at a time." />
                <meta property="og:description" content="HelixTech: Premier software engineering consulting firm specializing in web development, mobile apps, and custom software solutions. Get in touch today!" />
                <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
                <meta property="og:url" content="https://www.helixtech.org" />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Helix Tech | Your dreams, finished one line at a time." />
                <meta name="twitter:description" content="HelixTech: Premier software engineering consulting firm specializing in web development, mobile apps, and custom software solutions. Get in touch today!" />
                <meta name="twitter:image" content="%PUBLIC_URL%/logo192.png" />
            </Helmet>
            <Router>
                <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
                <Routes>
                    <Route path="/meet-the-team" element={<MeetTheTeam />} />
                    <Route path="/AboutUs" element={<AboutUs />} />
                    <Route path="/" element={
                        <>
                            <Hero />
                            <Box sx={{ bgcolor: 'background.default' }}>
                                <Procedure />
                                <Divider />
                                <ProductsWeOffer />
                                <Divider />
                                <FormAndPricing />
                                <Divider />
                                <FAQ />
                                <Footer />
                            </Box>
                        </>
                    } />
                </Routes>
            </Router>
        </ThemeProvider>
    );
}